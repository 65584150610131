import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'clsx'

import { IconAlertTriangle } from '@tabler/icons-react'

import { useGetMyAccount } from '@queries/Account'
import { useGetTeamUser } from '@queries/OrgStructure'
import { getAmounts } from '@pages/Accounting/CostCenter/utils'

import { CostCenterBodyResponse } from '@models/CostCenters'

import styles from './index.module.css'

export interface SpendingDetailsProps {
  data: CostCenterBodyResponse
  startDate?: string
}

interface SpendDetail {
  amount: number
  commited: number
  completed: number
  endDate: string
}

export const SpendingDetails: React.FC<SpendingDetailsProps> = ({ data, startDate }) => {
  const { t } = useTranslation('accounting', { keyPrefix: 'costCenter.details' })
  const { data: user } = useGetMyAccount()
  const { data: owner } = useGetTeamUser(data.owner)

  const { amount, commited, completed, endDate } = useMemo(() => {
    return data.budget_allocations.reduce<SpendDetail>(
      (acc, { amount, committed_amount, completed_amount, start_date, end_date }) => {
        if (startDate === start_date) {
          return {
            amount: acc.amount + (amount || 0),
            commited: acc.commited + (committed_amount || 0),
            completed: acc.completed + (completed_amount || 0),
            endDate: end_date,
          }
        }

        return acc
      },
      {
        amount: 0,
        commited: 0,
        completed: 0,
        endDate: '',
      },
    )
  }, [data, startDate])

  const totals = getAmounts(amount, completed, commited, user?.team?.default_currency)

  return (
    <div className="grid xl:grid-cols-2 gap-2 p-2 bg-white rounded-base">
      <div className="grid gap-2">
        <div className={styles.paramContainer}>
          <span className={styles.paramLabel}>{t('budgetOwnerLabel')}:</span>
          <span className={styles.paramValue}>{owner?.full_name}</span>
        </div>
        <div className={styles.paramContainer}>
          <span className={styles.paramLabel}>{t('startDateLabel')}:</span>
          <span className={styles.paramValue}>{startDate || ''}</span>
        </div>
        <div className={styles.paramContainer}>
          <span className={styles.paramLabel}>{t('endDateLabel')}:</span>
          <span className={styles.paramValue}>{endDate || ''}</span>
        </div>
      </div>
      <div className="border p-2 rounded-base grid gap-2">
        <div
          className={cx(styles.amountContainer, 'text-center bg-purple-50 border border-purple-200 text-brand_primary')}
        >
          {t('allocatedLabel')}: {totals.allocated}
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
          <div className={cx(styles.amountContainer, 'bg-emerald-50 border border-emerald-200 text-emerald-700')}>
            {t('spentLabel')} <div className={cx(styles.amountValue, 'border-emerald-200')}>{totals.spent}</div>
          </div>
          <div className={cx(styles.amountContainer, 'bg-sky-50 border border-sky-200 text-sky-700')}>
            {t('commitedLabel')} <div className={cx(styles.amountValue, 'border-sky-200')}>{totals.commited}</div>
          </div>
          {!totals.isOverspend && (
            <div className={cx(styles.amountContainer, 'bg-gray-50 border border-gray-200 text-gray-700')}>
              {t('remainingLabel')} <div className={cx(styles.amountValue, 'border-gray-200')}>{totals.remaining}</div>
            </div>
          )}
          {totals.isOverspend && (
            <div
              className={cx(
                styles.amountContainer,
                'bg-red-50 border border-red-200 text-red-700 grid-cols-2 justify-between',
              )}
            >
              <div className="grid gap-2">
                {t('overspendLabel')} <div className={cx(styles.amountValue, 'border-red-200')}>{totals.overspent}</div>
              </div>
              <div className="bg-amber-100 border-[6px] border-yellow-50 w-10 h-10 rounded-full flex items-center justify-center self-center place-self-end">
                <IconAlertTriangle className="stroke-amber-600 m-auto h-4 w-4" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default SpendingDetails
