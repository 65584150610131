import React, { useEffect, useMemo, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'

import Collapse from '@components/Collapse'
import { Select, EventHandler } from '@components/Form/Select'
import Button from '@components/Button'
import SelectedFilters from '@components/SelectedFilters'
import { IconAdjustmentsAlt } from '@tabler/icons-react'
import TabNavigation from '@pages/Accounting/CostCenter/Details/components/TabNavigation'

import { Filters as FilterType } from '@pages/Accounting/CostCenter/types'
import useStore from '../../../store'
import useGetUUIDFromPath from '@helpers/hooks/useGetUUIDFromPath'
import { useGetCostCenterBudgetBreakdownFilters } from '@queries/Accounting/costCenter'

import { Currencies, WorkflowType } from '@type/common'

export const Filters = () => {
  const { t } = useTranslation('accounting', { keyPrefix: 'costCenter.breakdownTab.filters' })
  const { t: tPo } = useTranslation('PO')
  const { id } = useGetUUIDFromPath()

  const [areFiltersCollapsed, setAreFiltersCollapsed] = useState(true)
  const setFilters = useStore.use.setFilters()
  const filters = useStore.use.filters()
  const periodOptions = useStore.use.periodOptions()
  const { data: availableFilters } = useGetCostCenterBudgetBreakdownFilters(id, filters.period)

  const formik = useFormik<Partial<FilterType>>({
    initialValues: filters,
    enableReinitialize: true,
    onSubmit: () => {
      if (formik.dirty) {
        setFilters(formik.values)
      }
    },
  })

  useEffect(() => {
    formik.submitForm()
  }, [formik.values])

  const currencyOptions = useMemo(
    () =>
      Object.values(Currencies).map((currency) => ({
        value: currency,
        label: currency,
      })),
    [availableFilters],
  )
  const categoryOptions = useMemo(
    () =>
      availableFilters?.category?.map((category) => ({
        value: category?.id,
        label: category?.name,
      })) || [],
    [availableFilters],
  )
  const vendorOptions = useMemo(
    () =>
      availableFilters?.vendor?.map((vendor) => ({
        value: vendor?.id,
        label: vendor?.name,
      })) || [],
    [availableFilters],
  )

  const typeOptions = useMemo(
    () => [
      {
        label: tPo('bill.title'),
        value: WorkflowType.bill,
      },
      {
        label: tPo('purchase_order.title'),
        value: WorkflowType.purchaseOrder,
      },
    ],
    [],
  )

  const handleChange: EventHandler<number | string | null> = useCallback(({ target: { value, name } }) => {
    if (name) {
      formik.setFieldValue(name, value)
    }
  }, [])

  const collapseFilters = useCallback(() => {
    setAreFiltersCollapsed(!areFiltersCollapsed)
  }, [areFiltersCollapsed])

  const selectedFilters = useMemo(() => {
    const filterValueGetters: Record<string, (value: unknown) => string | undefined | null> = {
      category: (value) => categoryOptions.find((option) => option.value === value)?.label,
      type: (value) => typeOptions.find((option) => option.value === value)?.label,
      vendor: (value) => vendorOptions.find((option) => option.value === value)?.label,
      currency: (value) => currencyOptions.find((option) => option.value === value)?.label,
    }
    return Object.entries(formik.values).reduce((acc, [key, value]) => {
      const getter = filterValueGetters[key]
      const newValue = getter?.(value)
      if (!newValue) return acc
      return {
        ...acc,
        [key]: newValue,
      }
    }, {})
  }, [formik.values, categoryOptions, vendorOptions, typeOptions, currencyOptions])

  const onClearFilter = useCallback((key: string) => {
    formik.setFieldValue(key, null)
  }, [])

  const clearAllFilters = useCallback(() => {
    setFilters({} as FilterType)
  }, [])

  return (
    <div>
      <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:lg:grid-cols-4 gap-2 px-1">
        <TabNavigation />
        <div className="w-full flex gap-2 lg:gap-1 sm:col-start-2 xl:col-start-3 lg:col-end-4 xl:col-end-5 justify-end">
          <Button
            type="outlined"
            className="w-auto"
            color={!areFiltersCollapsed ? 'primary' : 'default'}
            onClick={collapseFilters}
          >
            <IconAdjustmentsAlt className="w-4 h-4" />
            <span className="hidden sm:inline-block">{t('label')}</span>
          </Button>
          <div className="relative w-48">
            {!!periodOptions.length && (
              <Select
                options={periodOptions}
                value={formik.values.period}
                onChange={handleChange}
                className="!mb-0"
                name="period"
              />
            )}
          </div>
        </div>
      </div>
      <Collapse expanded={!areFiltersCollapsed}>
        <div className="px-2 gap-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 pt-3 ">
          <Select
            options={typeOptions}
            onChange={handleChange}
            name="type"
            placeholder={t('placeholders.type')}
            value={formik.values.type}
            isClearable
          />
          <Select
            options={categoryOptions}
            onChange={handleChange}
            value={formik.values.category}
            name="category"
            placeholder={t('placeholders.category')}
            isClearable
          />
          <Select
            options={vendorOptions}
            onChange={handleChange}
            value={formik.values.vendor}
            name="vendor"
            placeholder={t('placeholders.vendor')}
            isClearable
          />
          <Select
            options={currencyOptions}
            onChange={handleChange}
            value={formik.values.currency}
            name="currency"
            placeholder={t('placeholders.currency')}
            isClearable
          />
        </div>
      </Collapse>
      <SelectedFilters filters={selectedFilters} clearFilter={onClearFilter} clearAllFilters={clearAllFilters} />
    </div>
  )
}

export default React.memo(Filters)
