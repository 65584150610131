import React, { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@components/Button'
import { IconDeviceFloppy } from '@tabler/icons-react'
import usePopupHandler from '@components/Popup/usePopupHandler'
import Popup from '@components/Popup'
import Divider from '@components/Divider'
import { Input } from '@components/Form'
import Tooltip from '@components/Tooltip'

import { useCreateReport } from '@queries/Reports'
import { toast } from '@helpers/toaster'
import { requestErrorsHandler } from '@helpers/utils'
import Context from '@pages/ApprovalRequests/ApprovalRequestsContext/Context'

import { Status } from '@type/common'

const SaveToReportButton = () => {
  const { t } = useTranslation('reports', { keyPrefix: 'filters' })
  const { t: tCommon } = useTranslation('common')
  const { handleTogglePopup, isPopupOpen } = usePopupHandler()
  const { mutateAsync: createReport, isLoading } = useCreateReport()
  const { filters } = useContext(Context)
  const [name, setName] = useState('')

  const handleReportSave = useCallback(async () => {
    try {
      const body = {
        name: name,
        workflow: filters?.workflow ? Number(filters?.workflow) : null,
        requestor: filters?.requestor ? Number(filters?.requestor) : null,
        approver: filters?.approver ? Number(filters?.approver) : null,
        status: filters?.status ? (filters?.status as Status) : null,
      }
      await createReport(body)
      toast.success(t('savedToast'))
      handleTogglePopup()
      setName('')
    } catch (e) {
      requestErrorsHandler(e)
    }
  }, [name, filters])

  const handleNameChange: React.ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    const { value } = e.target
    setName(value)
  }, [])

  return (
    <>
      <Button
        color="white"
        className="w-6 min-w-0"
        data-tooltip-content={t('saveFiltersButton')}
        data-tooltip-id="save_to_report_tip"
        onClick={handleTogglePopup}
      >
        <IconDeviceFloppy className="w-4 h-4" />
      </Button>
      <Tooltip id="save_to_report_tip" place="bottom" />
      {isPopupOpen && (
        <Popup>
          <p>{t('popupTitle')}</p>
          <Divider />
          <Input
            label={t('nameLabel')}
            required
            placeholder={t('namePlaceholder')}
            onChange={handleNameChange}
            value={name}
          />
          <div className="flex justify-end gap-1.5">
            <Button color="white" onClick={handleTogglePopup} disabled={isLoading}>
              {tCommon('cancel')}
            </Button>
            <Button color="success" onClick={handleReportSave} loading={isLoading} disabled={!name}>
              {tCommon('save')}
            </Button>
          </div>
        </Popup>
      )}
    </>
  )
}

export default SaveToReportButton
