import { FileType, Status } from '@type/common'
import { Workflow } from '@models/Workflow'

export interface Report {
  id: number
  name: string
  workflow: Workflow<IsResponse>
  requestor: number
  approver: number
  status: Status
}

export interface ReportList {
  count: number
  next: string | null
  previous: string | null
  total: number | null
  results: Report[]
}

export interface Filter {
  search?: string
  page?: number
  size?: number
}

export interface ReportPayload {
  name: string
  workflow?: number | null
  requestor?: number | null
  approver?: number | null
  status?: Status | null
}

export enum Period {
  last_week = 'last_week',
  last_month = 'last_month',
  last_year = 'last_year',
  custom = 'custom',
}

export interface ExportParams {
  reportId?: number
  type?: FileType
  period?: Period
  with_approvers: boolean
}
