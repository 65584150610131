import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Cell, ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { generatePath, Link } from 'react-router-dom'

import Filters from './Filters'
import Table from '../Table'
import { BudgetBreakdown } from '@models/CostCenters'
import AmountCell from './AmountCell'
import VendorCell from './VendorCell'

import { useGetCostCenterBudgetBreakdown } from '@queries/Accounting/costCenter'
import useGetUUIDFromPath from '@helpers/hooks/useGetUUIDFromPath'
import useStore from '@pages/Accounting/CostCenter/store'
import { requestErrorsHandler, val2Amount } from '@helpers/utils'
import routes from '@src/Routes/routes'

type TableData = Partial<BudgetBreakdown>

const columnHelper = createColumnHelper<TableData>()

const BreakdownTab = () => {
  const { t } = useTranslation('accounting', { keyPrefix: 'costCenter.breakdownTab' })
  const { t: tPo } = useTranslation('PO')
  const { id } = useGetUUIDFromPath()

  const filters = useStore.use.filters()

  const { data, refetch } = useGetCostCenterBudgetBreakdown(
    id,
    filters.period,
    {
      vendor: filters.vendor,
      type: filters.type,
      currency: filters.currency,
      category: filters.category,
    },
    false,
  )

  useEffect(() => {
    try {
      if (filters?.period) {
        refetch()
      }
    } catch (e) {
      requestErrorsHandler(e)
    }
  }, [filters])

  const columns = useMemo<ColumnDef<TableData, any>[]>(() => {
    return [
      columnHelper.accessor('number', {
        header: t('table.columns.number'),

        cell: (cell) => {
          const item = cell.row.original
          const basePath = {
            bill: routes.private.billById,
            purchase_order: routes.private.purchaseOrdersById,
          }[item.type as string]

          if (basePath) {
            const path = generatePath(basePath, {
              id: item?.id,
            })
            return (
              <Link to={path} className="text-brand_primary hover:text-brand_secondary hover:outline-none">
                {item?.number}
              </Link>
            )
          }

          return <span>{item?.number}</span>
        },
      }),
      columnHelper.accessor((row) => (row.type ? tPo(`${row.type}.title`) : ''), {
        header: t('table.columns.type'),
      }),
      columnHelper.accessor((row) => row.category?.name, {
        header: t('table.columns.category'),
      }),
      columnHelper.accessor((row) => row.vendor?.name, {
        header: t('table.columns.vendor'),
        cell: VendorCell,
      }),
      columnHelper.accessor((row) => row.currency, {
        header: t('table.columns.currency'),
      }),
      columnHelper.accessor((row) => ({ value: row.committed, type: 'committed' }), {
        header: t('table.columns.committed'),
        cell: AmountCell,
      }),
      columnHelper.accessor((row) => ({ value: row.spent, type: 'spent' }), {
        header: t('table.columns.spent'),
        cell: AmountCell,
      }),
    ]
  }, [])

  const rowsData = useMemo<TableData[]>(() => {
    const breakdowns = data?.results
    if (!breakdowns?.length) return []

    const total = breakdowns.reduce(
      (acc, breakdown) => {
        acc.spent += Number(breakdown.spent) || 0
        acc.committed += Number(breakdown.committed) || 0
        return acc
      },
      { spent: 0, committed: 0 },
    )

    return [
      ...breakdowns,
      {
        spent: val2Amount(total.spent, breakdowns[0].currency),
        committed: val2Amount(total.committed, breakdowns[0].currency),
      },
    ]
  }, [data])

  return (
    <>
      <Filters />
      <Table
        data={rowsData}
        columns={columns}
        classes={{
          container: 'mt-6 max-h-[500px]',
          tr: () => '!h-9 last:bg-blue-50 last:sticky last:bottom-0',
          header: 'top-0 sticky',
          td: (data: Cell<TableData, any>) => {
            if (data.getValue()?.type) {
              return 'border-l-[1px] border-r-[1px]'
            }
            return ''
          },
        }}
      />
    </>
  )
}
export default BreakdownTab
