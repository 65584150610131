import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Input } from '@components/Form'
import TimeoffReportCard from './TimeoffReportCard'
import { SearchIcon } from '@heroicons/react/outline'

import useGetSearchHelpers from '@helpers/hooks/useGetSearchHelpers'
import ReportCard from '@pages/Reports/List/components/ReportCard'
import { Report } from '@models/Report'

interface List {
  reports?: Report[]

  updateSearchString?: (search: string) => void
}

const List: FC<List> = ({ reports = [], updateSearchString }) => {
  const { t } = useTranslation('reports', { keyPrefix: 'list' })
  const { inputValue, handleSearchChange, searchValue } = useGetSearchHelpers('')

  useEffect(() => {
    updateSearchString?.(searchValue)
  }, [searchValue])

  return (
    <div className="flex flex-col gap-4">
      <div className="base flex justify-end">
        <div className="relative sm:w-[310px] w-full">
          <Input
            value={inputValue}
            onChange={handleSearchChange}
            placeholder={t('searchPlaceholder')}
            classes={{ control: 'pr-8' }}
            className="!mb-0 w-full"
          />
          <SearchIcon className="h-4 w-4 absolute right-3 top-2 text-gray-300" />
        </div>
      </div>
      <div className="base flex gap-2 flex-wrap justify-center md:justify-start">
        <TimeoffReportCard />
        {reports.map((report) => (
          <ReportCard key={report.id} report={report} />
        ))}
        {/* {!reports?.length ? (
          <div className="flex justify-center w-full bg-white rounded-full py-3.5 text-neutral-400">
            {t('noRecordsTitle')}
          </div>
        ) : (
          reports.map((report) => <ReportCard key={report.id} report={report} />)
        )} */}
      </div>
    </div>
  )
}

export default List
