import React from 'react'

import { val2Amount } from '@helpers/utils'

import { BudgetBreakdown } from '@models/CostCenters'
import { ColumnDefTemplate } from '@tanstack/table-core'
import { CellContext } from '@tanstack/react-table'

const AmountCell: ColumnDefTemplate<CellContext<Partial<BudgetBreakdown>, any>> = (props) => {
  const value = props.getValue().value
  const result = typeof value === 'string' ? value : val2Amount(value, props.row.original.currency)

  return <div>{result}</div>
}

export default AmountCell
