import { BudgetAllocationResponse, CostCenterBodyResponse } from '@models/CostCenters'
import { BudgetAllocation, Form } from '../types'
import { BudgetFrequency, DeductedSumType } from '@type/budget'
import { Currencies } from '@type/currency'
import { v4 } from 'uuid'
import { TableHelper } from '../components/EditableTable/utils/TableHelper'
import { getYear, parse } from 'date-fns'

export class FormState implements Form {
  budgetAllocations: BudgetAllocation[]
  code: string
  currency: Currencies
  deductedSum: DeductedSumType
  filter: { year?: number | undefined }
  frequency: BudgetFrequency
  name: string
  owner?: number | undefined
  startDay: number
  startMonth: number

  private getBudget = (budget: BudgetAllocationResponse, groupId: string): BudgetAllocation => {
    const startDate = parse(budget.start_date, 'yyyy-MM-dd', new Date())
    const endDate = parse(budget.end_date, 'yyyy-MM-dd', new Date())

    return {
      startDate,
      endDate,
      name: budget.name,
      amount: (budget.amount || 0) / 100,
      category: budget.accounting_category?.id,
      id: v4(),
      groupId,
      isUncategorized: !budget.accounting_category?.id,
    }
  }

  private getBudgets = (
    budgets?: BudgetAllocationResponse[],
    budgetFrequency?: BudgetFrequency,
  ): BudgetAllocation[] => {
    const tableHelper = new TableHelper('')
    let budgetAllocations: BudgetAllocation[] = []

    const defaultAllocations = tableHelper.getNewCategoryPeriods(
      this.frequency,
      this.startDay,
      getYear(new Date()),
      this.startMonth,
      true,
    )

    if (budgets?.length) {
      budgetAllocations = budgets.map((budget) => {
        let amount = budget.amount
        if (!budget.accounting_category) {
          amount = budgets.reduce(
            (sum, sumBudget) => (sumBudget.start_date === budget.start_date ? sum + (sumBudget.amount || 0) : sum),
            0,
          )
        }
        return this.getBudget({ ...budget, amount }, budget.accounting_category?.name || 'Uncategorized')
      })

      // if yearly and there is not existion allocations for year add them
      if (budgetFrequency === BudgetFrequency.yearly) {
        const uniqueCategories = budgets.reduce<Map<number | undefined, string | undefined>>(
          (acc, budget) => acc.set(budget.accounting_category?.id, budget.accounting_category?.name),
          new Map(),
        )

        const notExistingAllocations = defaultAllocations.filter(
          (allocation) => !budgetAllocations.some((budget) => budget.name === allocation.name),
        )

        const newAllocations: BudgetAllocation[] = []
        Array.from(uniqueCategories).forEach((category) => {
          newAllocations.push(
            ...notExistingAllocations.map((allocation) => ({
              ...allocation,
              category: category[0],
              isUncategorized: !category[0],
              groupId: category[1] || 'Uncategorized',
            })),
          )
        })

        budgetAllocations.push(...newAllocations)
      }
    } else {
      budgetAllocations = defaultAllocations
    }

    return budgetAllocations
  }

  constructor(data?: CostCenterBodyResponse, currency?: Currencies) {
    this.name = data?.name || ''
    this.code = data?.code || ''
    this.owner = data?.owner
    this.deductedSum = data?.deducted_sum || DeductedSumType.gross
    this.frequency = data?.budget_frequency || BudgetFrequency.monthly
    this.startDay = data?.starting_day || 1
    this.startMonth = data?.starting_month || 0
    this.currency = currency || Currencies.USD
    this.filter =
      data?.budget_frequency !== BudgetFrequency.yearly
        ? {
            year: new Date().getFullYear(),
          }
        : {}
    this.budgetAllocations = this.getBudgets(data?.budget_allocations, data?.budget_frequency)
  }
}
