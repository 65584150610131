import cx from 'clsx'
import { useTranslation } from 'react-i18next'
import React, { FC, useMemo } from 'react'
import { IconRocket } from '@tabler/icons-react'
import { useNavigate } from 'react-router-dom'

import Button from '@src/components/Button'

import { useGetTeamSubscription } from '@src/api/queries/Billing'
import { plansData } from '../../constants'
import { isPlanLowerThanCurrent } from '../../utils'
import routes from '@src/Routes/routes'

import { Plan } from '@type/common'
import { RateType } from '@src/types/billing'

import styles from '../../index.module.css'

interface CardProps {
  className?: string
  plan: Plan
  onStartPlanClick: React.MouseEventHandler<HTMLButtonElement>
  setIsPopupVisible: React.Dispatch<React.SetStateAction<boolean>>
  isOnboardingAttended?: boolean
  billingFrequency?: RateType
}

interface FeatureListProps {
  features?: string[]
}

const FeatureList: FC<FeatureListProps> = ({ features = [] }) => (
  <div className="flex flex-col gap-4 mb-4 text-slate-950">
    {features.map((feature, index) => (
      <div key={index} className="flex gap-3">
        <div className={styles.bullet} />
        <p className="leading-6 whitespace-pre-line">{feature}</p>
      </div>
    ))}
  </div>
)

export const Card: FC<CardProps> = ({
  plan,
  className,
  onStartPlanClick,
  setIsPopupVisible,
  isOnboardingAttended,
  billingFrequency,
}) => {
  const { data: subscription } = useGetTeamSubscription()
  const { t } = useTranslation('billing', { keyPrefix: 'plans' })
  const navigate = useNavigate()

  const isYourPlan = subscription?.plan === plan
  const currentPlanData = plansData[plan]
  const isCustom = !!currentPlanData?.customPriceAnnual || !!currentPlanData?.customPriceMonthly
  // const isEnterprise = subscription?.plan === Plan.enterprise
  const isFree = subscription?.plan === Plan.free
  const isTrial = subscription?.is_trial
  const isActivePaidPlan = isYourPlan && !isTrial && !isFree

  const getPlanIncludesText = () => {
    if (plan === Plan.pro) {
      return t('proPlanIncludes')
    }
    if (plan === Plan.enterprise) {
      return t('enterprisePlanIncludes')
    }
    return t('planIncludes')
  }

  const PlanButton = useMemo(() => {
    // const isContactUs =
    //   (!isTrial && isPlanLowerThanCurrent(subscription?.plan, plan) && !isOnboardingAttended) ||
    //   isEnterprise ||
    //   isCustom

    const getButtonText = () => {
      switch (true) {
        case plan === Plan.free:
          return t('goToDashboard')
        case isActivePaidPlan:
          return t('yourPlan')
        // case isContactUs:
        //   return t('contactUs')
        default:
          return t('contactUs')
        // return t('buyButton')
      }
    }

    const icon = isActivePaidPlan && <IconRocket className="w-5 h-5 ml-1.5" />

    let onClickAction

    if (plan === Plan.free) {
      onClickAction = () => navigate(routes.private.dashboard)
    } else if (isActivePaidPlan) {
      onClickAction = () => setIsPopupVisible(false)
    } else {
      onClickAction = onStartPlanClick
    }

    return (
      <Button
        name={plan}
        data-frequency={billingFrequency}
        onClick={onClickAction}
        className="font-medium w-full my-6 h-10"
        color={plan === Plan.free ? 'white' : 'default'}
      >
        {icon}
        {getButtonText()}
      </Button>
    )
  }, [subscription, onStartPlanClick, billingFrequency])

  return (
    <div className={cx(styles.card, className, 'bg-white text-gray-500 min-w-[340px] xs:min-w-[400px]')}>
      <div className={cx('absolute top-0 right-0 w-full h-3', currentPlanData?.browColor)} />
      <div className="sticky-block">
        <div className="w-full flex gap-3 justify-between">
          <h3 className={cx('text-[20px] pb-1 font-medium leading-[30px] text-slate-950')}>{currentPlanData.name}</h3>
          {currentPlanData?.label && !isYourPlan && (
            <p className={cx(styles.labelCloud, 'text-emerald-700 bg-emerald-50 border-emerald-200')}>
              {currentPlanData?.label}
            </p>
          )}
        </div>
        <p className="h-[48px] mb-2 sm:mb-4">{currentPlanData?.shortDescription}</p>
        <p className={cx(styles.price, 'text-slate-950')}>
          {isCustom ? (
            <>
              <span>{currentPlanData?.customPriceAnnual || ''}</span>
              <p className="text-gray-500  text-3xl font-normal">{currentPlanData?.customPriceMonthly || ''}</p>
            </>
          ) : (
            <>
              <span>
                {billingFrequency === RateType.annually
                  ? `$${currentPlanData?.priceAnnualyPerMonth || ''}`
                  : `$${currentPlanData?.priceMonthly || ''}`}
              </span>
              <span className="text-[20px] font-normal ml-2.5">{t('perMonth')}</span>
              <p className="text-gray-500 text-3xl font-normal flex gap-2 min-h-9">
                {billingFrequency === RateType.annually && `$${currentPlanData?.priceAnnualy || ''} ${t('perYear')}`}
              </p>
            </>
          )}
        </p>
        {PlanButton}
      </div>
      <p className="text-[20px] font-medium leading-[30px] text-slate-950 mb-8">{getPlanIncludesText()}</p>
      <FeatureList features={currentPlanData?.mainFeatures} />
      <div>
        <FeatureList features={currentPlanData?.features} />
      </div>
      {/* <div className="absolute bottom-0 left-0 w-full px-8">{PlanButton}</div> */}
    </div>
  )
}
