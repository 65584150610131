import React, { useCallback } from 'react'
import { useField } from 'formik'
import CurrencyInput from 'react-currency-input-field'
import cx from 'clsx'
import { format, startOfDay } from 'date-fns'

import { useCostCenterContext } from '../../../utils'

import styles from '../index.module.css'

export interface AmountProps {
  index: number
  startDate?: Date
  currencySymbol?: string
  isTotal?: boolean
}

export const Amount: React.FC<AmountProps> = ({ index, currencySymbol, startDate, isTotal = false }) => {
  const fieldKey = `budgetAllocations[${index}].amount`
  const [{ name }, { error, value }, { setValue, setTouched }] = useField<string | undefined>(fieldKey)
  const { values, setFieldValue, getFieldMeta } = useCostCenterContext()

  const uncategorizedIndex = values.budgetAllocations.findIndex(
    (budget) =>
      budget.isUncategorized &&
      format(budget.startDate, 'yyyy-MM-dd') === format(startDate || startOfDay(new Date()), 'yyyy-MM-dd'),
  )

  const handleChange = useCallback(
    async (data?: string) => {
      if (name) {
        await setValue(data, true)
        await setTouched(true, false)
      }

      const totalKey = `budgetAllocations[${uncategorizedIndex}].amount`
      const totalMeta = getFieldMeta<string>(totalKey)

      if (!isTotal && uncategorizedIndex >= 0 && !totalMeta.touched) {
        const currentMeta = getFieldMeta<string>(fieldKey)

        const currentTotal = parseFloat(totalMeta.value || '0')
        const currentValue = parseFloat(data || '0')
        const prevValue = parseFloat(currentMeta.value || '0')
        const newTotal = currentTotal + (currentValue - prevValue)

        setFieldValue(`budgetAllocations[${uncategorizedIndex}].amount`, newTotal.toFixed(2), true)
      }
    },
    [getFieldMeta, name, values.budgetAllocations, startDate],
  )

  return (
    <CurrencyInput
      name={name}
      onValueChange={handleChange}
      value={value || '0'}
      prefix={currencySymbol}
      decimalSeparator="."
      groupSeparator=" "
      decimalsLimit={2}
      decimalScale={2}
      className={cx(styles.cell, { [styles.cellError]: !!error })}
    />
  )
}

export default React.memo(Amount)
