import React from 'react'

import { val2Amount } from '@helpers/utils'

import { BudgetBreakdown } from '@models/CostCenters'
import { ColumnDefTemplate } from '@tanstack/table-core'
import { CellContext } from '@tanstack/react-table'
import { generatePath, Link } from 'react-router-dom'
import routes from '@src/Routes/routes'
import { ContactParty } from '@models/ApproveitAccounting'

const VendorCell: ColumnDefTemplate<CellContext<Partial<BudgetBreakdown>, string | undefined>> = (props) => {
  const value = props.getValue()
  if (!value) return null

  const path = generatePath(routes.private.contact, {
    contactParty: ContactParty.vendor,
    id: props.row.original.vendor?.id,
  })
  return (
    <Link to={path} className="text-brand_primary hover:text-brand_secondary hover:outline-none">
      {value}
    </Link>
  )
}

export default VendorCell
