import { useCallback, useEffect } from 'react'
import * as amplitude from '@amplitude/analytics-browser'

import { useGetMyAccount } from '@queries/Account'
import { AMPLITUDE_API_KEY } from '@src/constants'
import Routes from '@src/Routes/routes'
import { generatePath } from 'react-router-dom'
import { ContactParty } from '@src/models/ApproveitAccounting'

export const pageViewEvents = {
  [Routes.private.dashboard]: 'page_view_dashboard',
  [Routes.private['']]: 'page_view_dashboard',
  [Routes.private.reports]: 'page_view_reports',
  [Routes.private.pipelines]: 'page_view_workflows',
  [generatePath(Routes.private.pipelineById, { id: 'new' })]: 'page_view_new_workflow',
  [Routes.private.pipelineById]: 'page_view_existing_workflow',
  [Routes.private.approvalRequests]: 'page_view_approval_requests',
  [Routes.private.pipelineApprovalRequest]: 'page_view_new_approval_request',
  [Routes.private.pipelineProcessApprovalRequest]: 'page_view_start_next_approval_request',
  [Routes.private.approvalRequestEditById]: 'page_view_edit_approval_request',
  [Routes.private.approvalRequestsById]: 'page_view_approval_request_details',
  [Routes.private.taskBoards]: 'page_view_tasks',
  [Routes.private.mailbox]: 'page_view_mailbox',
  [Routes.private.organizationalStructure]: 'page_view_team',
  [Routes.private.costCenter]: 'page_view_cost_centers',
  [Routes.private.bills]: 'page_view_bills',
  [Routes.private.purchaseOrders]: 'page_view_purchase_orders',
  [Routes.private.invoices]: 'page_view_invoices',
  [Routes.private.quotes]: 'page_view_quotes',
  [generatePath(Routes.private.contacts, { contactParty: ContactParty.vendor })]: 'page_view_vendors',
  [generatePath(Routes.private.contacts, { contactParty: ContactParty.customer })]: 'page_view_customers',
  [Routes.private.contracts]: 'page_view_contracts',
  [Routes.private.billing]: 'page_view_billing',
  [generatePath(Routes.private.settingsRouted, { tab: 'profile' })]: 'page_view_settings_profile',
  [generatePath(Routes.private.settingsRouted, { tab: 'security' })]: 'page_view_settings_security',
  [generatePath(Routes.private.settingsRouted, { tab: 'team' })]: 'page_view_settings_team',
  [generatePath(Routes.private.settingsRouted, { tab: 'integration' })]: 'page_view_settings_integrations',
  [generatePath(Routes.private.settingsRouted, { tab: 'notifications' })]: 'page_view_settings_notifications',
  [Routes.private.paywall]: 'page_view_paywall',
}

export const sendEvent = (eventName: string, data: Record<string, unknown> = {}) => {
  if (AMPLITUDE_API_KEY) {
    amplitude.track(eventName, { source: 'web', ...data })
  }
}

export const useAmplitude = () => {
  const { data: user } = useGetMyAccount()

  const handleClick = useCallback((e: MouseEvent) => {
    const target = e.target as HTMLElement
    const button = target?.closest('[data-amplitude]')
    const buttonEvent = button?.getAttribute('data-amplitude')

    if (buttonEvent) {
      sendEvent(`click_${buttonEvent}`, { button: buttonEvent })
    }
  }, [])

  useEffect(() => {
    if (!AMPLITUDE_API_KEY) {
      return
    }
    if (!amplitude.getDeviceId()) {
      amplitude.init(AMPLITUDE_API_KEY, { autocapture: false })
    }

    if (user) {
      const identifyEvent = new amplitude.Identify()
      identifyEvent.set('auth_user_id', user.id)
      identifyEvent.set('team_user_id', user.team_user_id || '')
      identifyEvent.set('user_role', user.role)
      if (user.team) {
        amplitude.setUserId(`${user.team.name} - User:${user.id}`)
        identifyEvent.set('team_name', user.team.name)
        identifyEvent.set('team_id', user.team.id)
        identifyEvent.set('team_plan', user.team.subscription.plan)
      }
      amplitude.identify(identifyEvent)
    }

    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [user?.id])
}
export default useAmplitude
