import { useCreate, useDelete, useGetList, useGetOne, useUpdate } from '@queries/request'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { generatePath } from 'react-router-dom'
import { format } from 'date-fns'

import api from '@api/api'

import { OrgStructureKeys, ReportKeys } from '@queries/Keys'
import { Report, Filter, ReportPayload, ExportParams } from '@models/Report'
import { sendEvent } from '@helpers/hooks/useAmplitude'

const PATHS = {
  reports: '/api/reports/',
  report: '/api/reports/:id',
  export: '/api/reports/export',
}

export const useGetReports = (filter?: Filter, enabled = true) => {
  return useGetList<Report>([ReportKeys.Reports], PATHS.reports, {
    query: {
      enabled,
    },
    axios: {
      params: { ...(filter || {}) },
    },
  })
}

export const useGetReport = (id?: string) => {
  return useGetOne<Report>([ReportKeys.Report, id || ''], generatePath(PATHS.report, { id: id }), {
    query: {
      enabled: !!id,
      suspense: true,
    },
  })
}

export const useCreateReport = () => {
  return useCreate<ReportPayload, Report>(PATHS.reports, {
    mutation: {
      onSuccess: () => {
        sendEvent('report_created')
      },
    },
  })
}

export const useDeleteReport = () => {
  const cache = useQueryClient()
  return useDelete<number>((id) => generatePath(PATHS.report, { id }), {
    mutation: {
      onSuccess: () => {
        cache.invalidateQueries([ReportKeys.Reports])
      },
    },
  })
}

export const useEditReport = (id?: string) => {
  const cache = useQueryClient()
  return useUpdate<Partial<ReportPayload>, Report>(generatePath(PATHS.report, { id: id || '' }), {
    mutation: {
      onSuccess: () => {
        cache.invalidateQueries([ReportKeys.Report, id])
        cache.invalidateQueries([ReportKeys.Reports])
      },
    },
  })
}

export const useGenerateReport = (reportName: string, reportType?: string) => {
  return useMutation(
    async (params: ExportParams) => {
      const path = reportType ? `${PATHS.export}/${reportType}` : PATHS.export
      const response = await api.get(path, {
        params: Object.keys(params).reduce(
          (acc, key) =>
            params[key as keyof ExportParams] ? { ...acc, [key]: params[key as keyof ExportParams] } : acc,
          {},
        ),
        responseType: 'blob',
      })
      if (response.status === 204) {
        return response
      }
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        `${reportName || reportType || 'report'}_${format(new Date(), 'yyyy-MM-dd-hh-mm')}.${params.type}`,
      )
      document.body.appendChild(link)
      link.click()

      return null
    },
    {
      mutationKey: [OrgStructureKeys.Export],
    },
  )
}
