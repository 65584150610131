import axios, { AxiosError } from 'axios'
import Cookies from 'js-cookie'
import { API_ADDRESS } from '../constants'
import * as Sentry from '@sentry/react'

import { RefreshTokenRequest, RefreshTokenResponse } from '@models/Account'
import { clearTokens, decodeToken, setTokens } from './token'

const token = localStorage.getItem('token') || Cookies.get('token')

const REFRESH_TOKEN_PATH = '/api/token/refresh/'

const axiosInstance = axios.create({
  baseURL: API_ADDRESS,
  headers: {
    Authorization: token ? `Token ${token}` : undefined,
  },
  withCredentials: true,
})

let refreshTokenPromise: Promise<string | void> | null = null

const refreshToken = async (): Promise<string | void> => {
  const refreshToken = localStorage.getItem('refreshToken') || Cookies.get('refreshToken')

  if (!refreshToken) {
    throw new Error('No refresh token available')
  }
  try {
    const refreshResponse = await axios.post<RefreshTokenResponse>(
      REFRESH_TOKEN_PATH,
      {
        refresh: refreshToken,
      } as RefreshTokenRequest,
      {
        baseURL: API_ADDRESS,
        withCredentials: true,
      },
    )

    setTokens(refreshResponse.data.access, refreshResponse.data.refresh)

    axiosInstance.defaults.headers.Authorization = `Token ${refreshResponse.data.access}`
    return refreshResponse.data.access
  } catch (err) {
    if (err) {
      clearTokens()

      axiosInstance.defaults.headers.Authorization = ''
      throw err
    }
  }
}

export const checkTokenIsExpired = () => {
  const token = localStorage.getItem('token') || Cookies.get('token')

  if (!token) {
    return false
  }
  const decoded = decodeToken(token)

  if (!decoded.exp) {
    return false
  }

  if (decoded.exp < Date.now() / 1000 + 60) {
    return true
  }
  return false
}

if (token) {
  axiosInstance.defaults.headers.Authorization = `Token ${token}`
}

axiosInstance.interceptors.request.use(async (req) => {
  if (checkTokenIsExpired()) {
    if (!refreshTokenPromise) {
      refreshTokenPromise = refreshToken()
        .catch(() => {
          clearTokens()
          window.location.pathname = '/login'
        })
        .finally(() => {
          refreshTokenPromise = null
        })
    }

    const newAccessToken = await refreshTokenPromise
    if (!newAccessToken) {
      const controller = new AbortController()
      const cfg = {
        ...req,
        signal: controller.signal,
      }
      controller.abort()
      return cfg
    }
    if (newAccessToken) {
      req.headers.Authorization = `Token ${newAccessToken}`
    }
  }

  return req
})

axiosInstance.interceptors.response.use(undefined, (err: AxiosError) => {
  const url = err?.response?.config?.url
  if (
    err?.response?.status === 401 &&
    !['quickbooks', 'xero', 'softledger'].some((integration) => url?.includes(integration)) &&
    !refreshTokenPromise
  ) {
    clearTokens()
    window.location.pathname = '/login'
    axiosInstance.defaults.headers.Authorization = ''
  }

  if (err?.response?.status === 404 && err?.config?.url?.includes('/company')) {
    throw err
  }

  if ((err?.response?.status || 0) > 400) {
    Sentry.captureException(err)
  }

  throw err
})

export default axiosInstance
