import React, { FC } from 'react'
import { XIcon } from '@heroicons/react/outline'

import styles from './index.module.css'
import { useTranslation } from 'react-i18next'
import Button from '@components/Button'
import { IconX } from '@tabler/icons-react'
import SaveToReportButton from '@components/SelectedFilters/SaveToReportButton'
import Tooltip from '@components/Tooltip'

interface SelectedFiltersProps {
  filters: Record<string, string>
  showSaveToReportButton?: boolean

  clearFilter(key: string): void

  clearAllFilters(): void
}

const SelectedFilters: FC<SelectedFiltersProps> = ({
  filters,
  clearFilter,
  clearAllFilters,
  showSaveToReportButton = false,
}) => {
  const { t } = useTranslation('common')

  const filtersEntries = Object.entries(filters).filter(([_, value]) => Boolean(value))
  if (!filtersEntries.length) return null

  const renderFilterValue = (key: string) => {
    if (key === 'jobRole') return `${t('jobTitle')}: `
    if (key === 'actionRequired' || key === 'action_required') return ''
    return `${key}: `
  }

  return (
    <div className={styles.container}>
      <div className="font-bold">{t('selectedFilters')}:</div>
      {filtersEntries.map(([key, value]) => (
        <div key={key} className={styles.badge}>
          <p className={styles.filterValue}>
            {renderFilterValue(key)}
            <span className="max-h-6 bg-white rounded-lg px-2 py-0.5">{value}</span>
          </p>
          <button className={styles.clearFilterIcon} onClick={() => clearFilter(key)}>
            <XIcon width={14} />
          </button>
        </div>
      ))}
      <div className="flex gap-1.5">
        {showSaveToReportButton && <SaveToReportButton />}
        <Button
          color="white"
          onClick={clearAllFilters}
          className="w-6 min-w-0"
          data-tooltip-content={t('clearAllLabel')}
          data-tooltip-id="clear_tooltip"
        >
          <IconX className="w-3 h-3" stroke={3} />
        </Button>
        <Tooltip id="clear_tooltip" place="bottom" className="z-10" />
      </div>
    </div>
  )
}

export default SelectedFilters
