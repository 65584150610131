import React, { useCallback, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Formik, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { ActionMeta, SingleValue } from 'react-select'
import { addDays, addMonths, setMonth } from 'date-fns'
import cx from 'clsx'
import { toast } from '@src/helpers/toaster'

import Input from '@components/Form/Input'
import Select, { useSelectHelpers } from '@components/Form/ReactSelect'
import Button from '@components/Button'
import Header from '@components/Header'
import CurrencySelector from '@components/CurrencySelector'
import UserSelectV2 from '@components/UserSelectV2'
import EditableTable from '@pages/Accounting/CostCenter/Form/components/EditableTable'

import useGetUUIDFromPath from '@helpers/hooks/useGetUUIDFromPath'
import { useGetMyAccount } from '@queries/Account'
import { useCreateCostCenter, useGetCostCenterDetail, useUpdateCostCenter } from '@queries/Accounting/costCenter'

import { getNestedError, requestErrorsHandler } from '@helpers/utils'
import routes from '@src/Routes/routes'
import { Schema } from './utils'
import { BudgetFrequencyOptions, DeductedSumTypeOptions, FilterYear, StartDayOptions } from '../utils/constants'
import { RequestBody } from './utils/RequestBody'
import { FormState } from './utils/FormState'
import { TableHelper } from './components/EditableTable/utils/TableHelper'

import { BudgetFrequency } from '@type/budget'
import { Form } from './types'

export interface CostCenterFormProps {
  loading?: boolean
}

const monthsOptions = Array.from({ length: 12 }, (_, i) => {
  const date = new Date(0, i) // Create a date with month index i
  return { label: date.toLocaleString('en-US', { month: 'long' }), value: i }
})

export const CostCenterForm: React.FC<CostCenterFormProps> = ({ loading = false }) => {
  const { t } = useTranslation(['common', 'accounting'])

  const { isNew } = useGetUUIDFromPath()
  const { data: user } = useGetMyAccount()

  const { values, errors, isValid, handleChange, setFieldValue, handleSubmit, setValues, isSubmitting, isValidating } =
    useFormikContext<Form>()

  const handleSelectChange = useCallback(
    (option: SingleValue<Option<string | number | undefined>>, meta: ActionMeta<Option<number | string>>) =>
      meta.name && setFieldValue(meta.name, option?.value),
    [],
  )

  const { optionValue: deductedsumOption } = useSelectHelpers(DeductedSumTypeOptions, values?.deductedSum)
  const { optionValue: frequencyOption } = useSelectHelpers(BudgetFrequencyOptions, values?.frequency)
  const { optionValue: startDayOption } = useSelectHelpers(StartDayOptions, values?.startDay)
  const { optionValue: startMonthOption } = useSelectHelpers(monthsOptions, values?.startMonth)

  const SubmitButtons = useMemo(() => {
    return (
      <Button form="cost-center-form" buttonType="submit" color="primary" loading={loading}>
        {t('common:save')}
      </Button>
    )
  }, [isValid])

  const handleFrequencyChange = useCallback(async (option: SingleValue<Option<BudgetFrequency>>) => {
    setValues((values) => ({
      ...values,
      frequency: option?.value || BudgetFrequency.monthly,
      filter: {
        ...values.filter,
        year: option?.value !== BudgetFrequency.yearly ? FilterYear.currentYear : undefined,
      },
      budgetAllocations: new TableHelper('').getNewCategoryPeriods(
        option?.value || BudgetFrequency.monthly,
        values.startDay,
        values.filter.year,
        values.startMonth,
        true,
      ),
    }))
  }, [])

  const handleStartDayChange = useCallback(async (option: SingleValue<Option<number>>) => {
    setValues((values) => ({
      ...values,
      budgetAllocations: values.budgetAllocations.map((budget) => {
        const daysDiff = (option?.value || 1) - values.startDay
        const startDate = addDays(budget.startDate, daysDiff)
        const endDate = addDays(budget.endDate, daysDiff)
        return { ...budget, startDate, endDate }
      }),
      startDay: option?.value || 1,
    }))
  }, [])

  const handleStartMonthChange = useCallback(async (option: SingleValue<Option<number>>) => {
    setValues((values) => ({
      ...values,
      budgetAllocations: values.budgetAllocations.map((budget) => {
        const monthsDiff = (option?.value || 0) - values.startMonth
        const startDate = addMonths(budget.startDate, monthsDiff)
        const endDate = addMonths(budget.endDate, monthsDiff)
        return { ...budget, startDate, endDate }
      }),
      startMonth: option?.value || 0,
    }))
  }, [])

  useEffect(() => {
    if (isSubmitting && Object.keys(errors).length && !isValidating) {
      toast.error(getNestedError(errors))
    }
  }, [errors, isSubmitting, isValidating])

  return (
    <>
      <Header
        buttons={SubmitButtons}
        title={isNew ? t('accounting:costCenter.addNew') : t('accounting:costCenter.edit')}
        backUrl={routes.private.costCenter}
      />
      <form id="cost-center-form" className="flex flex-col bg-alice_blue p-4 rounded-lg" onSubmit={handleSubmit}>
        <Input
          name="name"
          value={values.name}
          onChange={handleChange}
          label={t('accounting:costCenter.form.nameLabel')}
          errorMessage={errors.name}
        />
        <Input
          name="code"
          value={values.code}
          onChange={handleChange}
          label={t('accounting:costCenter.form.codeLabel')}
          errorMessage={errors.code}
        />
        <UserSelectV2
          onChange={(value) => setFieldValue('owner', value)}
          label={t('accounting:costCenter.form.ownerLabel')}
          value={values.owner}
          required
          errorMessage={errors.owner}
        />
        <Select
          name="deductedSum"
          options={DeductedSumTypeOptions}
          value={deductedsumOption}
          onChange={handleSelectChange}
          label={t('accounting:costCenter.form.deductedSumLabel')}
          menuPortalTarget={document.body}
        />
        <div
          className={cx(
            'grid gap-2 ',
            values.frequency === BudgetFrequency.yearly ? 'sm:grid-cols-3' : 'sm:grid-cols-2',
          )}
        >
          <Select
            name="frequency"
            options={BudgetFrequencyOptions}
            value={frequencyOption}
            onChange={handleFrequencyChange}
            label={t('accounting:costCenter.form.budgetFrequencyLabel')}
            menuPortalTarget={document.body}
            isDisabled={!isNew}
          />
          {values.frequency === BudgetFrequency.yearly && (
            <Select
              name="startMonth"
              options={monthsOptions}
              value={startMonthOption}
              onChange={handleStartMonthChange}
              label={t('accounting:costCenter.form.startingMonthLabel')}
              menuPortalTarget={document.body}
              isDisabled={!isNew}
            />
          )}
          <Select
            name="startDay"
            options={StartDayOptions}
            value={startDayOption}
            onChange={handleStartDayChange}
            label={t('accounting:costCenter.form.startingDayLabel')}
            menuPortalTarget={document.body}
            isDisabled={!isNew}
          />
        </div>
        <CurrencySelector
          name="currency"
          value={user?.team?.default_currency as string}
          onChange={handleSelectChange}
          label={t('accounting:costCenter.form.currencyLabel')}
          menuPortalTarget={document.body}
          isDisabled
          className="!mb-2"
        />
        {values.frequency !== BudgetFrequency.custom && <EditableTable />}
      </form>
    </>
  )
}

const CostCenterFormWrapper = () => {
  const { id, isNew } = useGetUUIDFromPath()
  const { data: user } = useGetMyAccount()
  const { mutateAsync: createCostCenter, isLoading: isCreateLoading } = useCreateCostCenter()
  const { mutateAsync: updateCostCenter, isLoading: isUpdateLoading } = useUpdateCostCenter(id as string)
  const { data: costCenterDetail } = useGetCostCenterDetail(id, !isNew)

  const navigate = useNavigate()

  const initialData = useMemo<Form>(() => {
    return new FormState(costCenterDetail, user?.team?.default_currency)
  }, [costCenterDetail])

  return (
    <Formik<Form>
      validationSchema={Schema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialData}
      onSubmit={async (values) => {
        try {
          const body = new RequestBody(values)
          if (id) {
            await updateCostCenter(body)
          } else {
            await createCostCenter(body)
          }
          navigate(routes.private.costCenter)
        } catch (err) {
          requestErrorsHandler(err)
        }
      }}
    >
      <CostCenterForm loading={isCreateLoading || isUpdateLoading} />
    </Formik>
  )
}
export default React.memo(CostCenterFormWrapper)
