import React, { Dispatch, SetStateAction } from 'react'
import { FilterState } from '@pages/ApprovalRequests/RequestsList/Filter/type'
import { Status } from '@type/common'

interface DefaultProps {
  filters: FilterState
  areFiltersCollapsed: boolean
  selectedRequestIds: { id: number; status: Status }[]
  setFilters: React.Dispatch<React.SetStateAction<FilterState>>
  setSelectedRequestIds: Dispatch<SetStateAction<{ id: number; status: Status }[]>>
  setAreFiltersCollapsed: Dispatch<SetStateAction<boolean>>
}

export const Default: DefaultProps = {
  filters: {
    status: '',
    date: {},
    workflow: '',
    actionRequired: false,
    requestor: '',
    approver: '',
    search: '',
    page: 1,
    limit: 1,
  },
  areFiltersCollapsed: false,
  selectedRequestIds: [],
  setFilters: () => null,
  setSelectedRequestIds: () => null,
  setAreFiltersCollapsed: () => null,
}

export const Context = React.createContext(Default)

export default Context
