import React from 'react'
import cx from 'clsx'

import Popup from '@components/Popup'
import { Plans } from '../../Plans'

export interface PlansPopupProps {
  setIsPopupVisible: React.Dispatch<React.SetStateAction<boolean>>
  onStartPlanClick: React.MouseEventHandler<HTMLButtonElement>
}

export const PlansPopup: React.FC<PlansPopupProps> = ({ setIsPopupVisible, onStartPlanClick }) => {
  return (
    <Popup
      onClose={() => setIsPopupVisible(false)}
      className={cx('no-scrollbar w-full max-w-none sm:w-fit h-fit mx-1 sm:mx-1 py-6 px-0 max-h-[99%]')}
      containerStyle="items-center"
    >
      <Plans setIsPopupVisible={setIsPopupVisible} onStartPlanClick={onStartPlanClick} />
    </Popup>
  )
}

export default PlansPopup
