import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import ReposrtCardContent from './ReportCardContent'
import { usePopupHandlers } from '@components/Popup'
import GenerateReportPopup from './GenerateReportPopup'

interface ReportCardProps {}

const ReportCard: FC<ReportCardProps> = () => {
  const { t } = useTranslation('reports', { keyPrefix: 'card' })
  const { isPopupOpen, handleTogglePopup } = usePopupHandlers()

  const handleGenerateReportClick: React.MouseEventHandler<HTMLButtonElement> = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    handleTogglePopup()
  }, [])

  return (
    <div className="flex p-3 rounded-xl bg-white w-[20rem] justify-between gap-1 flex-shrink-0 cursor-pointer">
      <ReposrtCardContent
        name={t('timeOffReportTitle')}
        onGenerateClick={handleGenerateReportClick}
        isGeneratedByApproveit
      />
      {isPopupOpen && <GenerateReportPopup close={handleTogglePopup} reportType="timeoff" allowApprovers={false} />}
    </div>
  )
}

export default ReportCard
