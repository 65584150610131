import React, { useMemo } from 'react'
import {
  format,
  lastDayOfMonth,
  lastDayOfQuarter,
  lastDayOfYear,
  setQuarter,
  setDate,
  setMonth,
  setYear,
  addDays,
  addMonths,
  startOfDay,
} from 'date-fns'
import { createColumnHelper } from '@tanstack/react-table'
import { v4 } from 'uuid'

import Amount from '../components/Amount'

import { useGetMyAccount } from '@queries/Account'
import { getCurrencySymbol } from '@helpers/utils'
import { FilterYear } from '@pages/Accounting/CostCenter/utils/constants'

import { BudgetAllocation } from '../../../types'
import { BudgetFrequency } from '@type/budget'
import UncategorizedAmount from '../components/UncategorizedAmount'

export class TableHelper {
  public currencySymbol = ''
  private columnHelper = createColumnHelper<BudgetAllocation>()
  private startYear = FilterYear.startYear

  constructor(currencySymbol: string) {
    this.currencySymbol = currencySymbol
  }

  private getMonthName = (monthNumber: number) => {
    const date = new Date()
    date.setDate(1)
    date.setMonth(monthNumber)
    return format(date, 'LLL')
  }

  private getQuaterName = (quaterNumber: number) => {
    let date = new Date()
    date.setDate(1)
    date = setQuarter(date, quaterNumber + 1)
    return format(date, 'qqq')
  }

  private getYearName = (yearNumber: number) => {
    let date = new Date()
    date.setDate(1)
    date = setYear(date, yearNumber + this.startYear)
    return format(date, 'yyyy')
  }

  public getPeriodDates = (
    period: number,
    frequency: BudgetFrequency,
    startDay: number,
    year?: number,
    startMonth?: number,
  ) => {
    let periodNumber = period

    if (frequency === BudgetFrequency.quarterly) {
      periodNumber += 1
    }
    if (frequency === BudgetFrequency.yearly) {
      periodNumber += FilterYear.startYear
    }

    let date = new Date()
    date = startOfDay(date)
    let startDate = year ? setYear(date, year) : date

    startDate.setMonth(frequency === BudgetFrequency.yearly ? startMonth || 0 : 0)
    startDate = this.setPeriodStartDate[frequency](startDate, periodNumber)
    startDate = setDate(startDate, startDay)

    let endDate = this.setPeriodEndDate[frequency](startDate)

    endDate = addDays(endDate, startDay - 1)
    if (frequency === BudgetFrequency.yearly) {
      endDate = addMonths(endDate, startMonth || 0)
    }

    return { startDate, endDate }
  }

  private getPeriodColumn = (periodNumber: number, frequency: BudgetFrequency, startDay = 1, startMonth = 0) => {
    const isCrossYearBudget = !!(frequency === BudgetFrequency.yearly && (startMonth > 0 ? true : startDay > 1))
    const thisPeriodName = this.periodNameGetters[frequency](periodNumber)
    const header = isCrossYearBudget ? `${thisPeriodName} - ${+thisPeriodName + 1}` : thisPeriodName
    return this.columnHelper.accessor((row) => row.amount, {
      header,
      cell: ({ row }) => {
        if (row.original.isUncategorized) {
          return (
            <UncategorizedAmount
              currencySymbol={this.currencySymbol}
              startDate={row.subRows[periodNumber]?.original?.startDate}
            />
          )
        }
        return (
          <Amount
            index={row.subRows[periodNumber].index}
            currencySymbol={this.currencySymbol}
            startDate={row.subRows[periodNumber]?.original?.startDate}
          />
        )
      },
      meta: {
        totalAccessor: periodNumber,
      },
    })
  }

  private frequencyPeriods = {
    [BudgetFrequency.monthly]: 12,
    [BudgetFrequency.quarterly]: 4,
    [BudgetFrequency.yearly]: FilterYear.options.length,
    [BudgetFrequency.custom]: 0,
  }

  private periodNameGetters = {
    [BudgetFrequency.monthly]: this.getMonthName,
    [BudgetFrequency.quarterly]: this.getQuaterName,
    [BudgetFrequency.yearly]: this.getYearName,
    [BudgetFrequency.custom]: this.getMonthName,
  }

  public setPeriodStartDate = {
    [BudgetFrequency.monthly]: setMonth,
    [BudgetFrequency.quarterly]: setQuarter,
    [BudgetFrequency.yearly]: setYear,
    [BudgetFrequency.custom]: setMonth,
  }

  private setPeriodEndDate = {
    [BudgetFrequency.monthly]: lastDayOfMonth,
    [BudgetFrequency.quarterly]: lastDayOfQuarter,
    [BudgetFrequency.yearly]: lastDayOfYear,
    [BudgetFrequency.custom]: lastDayOfMonth,
  }

  public periodNameFormats = {
    [BudgetFrequency.monthly]: 'LLL yyyy',
    [BudgetFrequency.quarterly]: 'qqq yyyy',
    [BudgetFrequency.yearly]: 'yyyy',
    [BudgetFrequency.custom]: '',
  }

  public getPeriodColumns = (frequency: BudgetFrequency, startDay?: number, startMonth?: number) => {
    return [...Array(this.frequencyPeriods[frequency]).keys()].map((period) => {
      return this.getPeriodColumn(period, frequency, startDay, startMonth)
    })
  }

  public getNewCategoryPeriods = (
    frequency: BudgetFrequency,
    startDay: number,
    year?: number,
    startMonth?: number,
    isUncategorized = false,
  ): BudgetAllocation[] => {
    const groupId = v4()
    return [...Array(this.frequencyPeriods[frequency]).keys()].map((period) => {
      const { startDate, endDate } = this.getPeriodDates(period, frequency, startDay, year, startMonth)
      return {
        name: format(startDate, this.periodNameFormats[frequency]),
        startDate,
        endDate,
        amount: 0,
        id: v4(),
        groupId,
        isUncategorized,
      }
    })
  }

  public getIsSamePeriod = (frequency: BudgetFrequency, dateLeft: Date, dateRight: Date) => {
    const dateFormat = this.periodNameFormats[frequency]

    return format(dateLeft, dateFormat) === format(dateRight, dateFormat)
  }
}

export const useTableHelpers = () => {
  const { data: user } = useGetMyAccount()
  const currencySymbol = useMemo(
    () => (user?.team?.default_currency ? getCurrencySymbol(user?.team?.default_currency) : ''),
    [],
  )

  const tableHelpers = useMemo(() => new TableHelper(currencySymbol), [])

  return tableHelpers
}
