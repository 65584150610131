import { generatePath } from 'react-router-dom'
import { useQueryClient, useMutation, MutationFunction, useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { format } from 'date-fns'
import qs from 'qs'

import {
  DepartmentResponse,
  DepartmentsResponse,
  OrgStructureResponse,
  DepartmentDetails,
  JobRoleResponse,
  LocationResponse,
  FiltersResponse,
  FilterParams,
  ExportFilter,
  TeamUserDetails,
  BulkUpdateRequest,
  LocationsResponse,
  ImportCSVResponse,
} from '@models/OrgStructure'

import api from '../../api'

import { AccountKeys, OrgStructureKeys } from '../Keys'
import { useGetOne, useCreate, useDelete, usePatch, useGetList } from '../request'
import { Role } from '@type/common'
import { InviteUser } from '@models/Team'

const PATHS = {
  orgstructure: 'api/teams/orgstructure',
  invites: 'api/teams/orgstructure/invites',
  user: 'api/teams/users/:id',
  filters: 'api/teams/orgstructure/filters',
  bulkUpdate: 'api/teams/users/bulk',

  departments: 'api/teams/departments',
  departmentById: 'api/teams/departments/:id',
  jobRoles: 'api/teams/departments/:departmentId/job-roles',
  jobRoleById: 'api/teams/departments/:departmentId/job-roles/:id',

  locations: 'api/teams/locations',
  locationById: 'api/teams/locations/:id',

  import: 'api/teams/orgstructure/import',
  export: 'api/teams/orgstructure/export',
  slackImport: 'api/teams/users/import/slack',
  microsoftImport: 'api/teams/users/import/microsoft',
  bambooHRImport: 'api/teams/users/import/bamboo',

  taskStatus: 'task-status',
}

export const useGetTeamOrgStructure = (filter?: FilterParams, enabled = true) => {
  return useGetOne<OrgStructureResponse>([OrgStructureKeys.OrgStructure], PATHS.orgstructure, {
    query: {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled,
    },
    axios: {
      params: { ...(filter || {}) },
    },
  })
}
export const useGetTeamDropdownList = (filter?: FilterParams, enabled = true) => {
  return useGetOne<OrgStructureResponse>([OrgStructureKeys.OrgStructureDropdown], PATHS.orgstructure, {
    query: {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled,
    },
    axios: {
      params: { ...(filter || {}) },
    },
  })
}

type UserFilters = { ids: number[]; key?: string; size?: number; search?: string }
export const useGetUsersByIds = (filter?: UserFilters) => {
  const ids = filter?.ids?.filter((el) => typeof el === 'number')

  const queryKey = [OrgStructureKeys.OrgStructureDropdownById]
  if (filter?.key) {
    queryKey.push(filter?.key)
  }
  return useQuery<OrgStructureResponse>(
    queryKey,
    async () => {
      if (ids?.length) {
        const response = await api.get<OrgStructureResponse>(PATHS.orgstructure, {
          params: { ids, size: filter?.size || filter?.ids?.length || 0, search: filter?.search },
          paramsSerializer: (param) => {
            return qs.stringify(param, { arrayFormat: 'repeat' })
          },
        })
        return response.data
      }
      return { count: 0, results: [] }
    },
    {
      enabled: !!ids?.length,
      placeholderData: {
        results: [],
        count: 0,
      },
    },
  )
}
export const useGetTeamInvites = () => {
  return useGetList<InviteUser>([OrgStructureKeys.Invites], PATHS.invites, {
    query: {
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  })
}

export const useGetOrgStructureFilters = () => {
  return useGetOne<FiltersResponse>([OrgStructureKeys.Filters], PATHS.filters)
}

export const useGetTeamDepartments = (enabled = true, notEmpty = false) => {
  return useGetOne<DepartmentsResponse>([OrgStructureKeys.TeamDepartments, notEmpty], PATHS.departments, {
    axios: {
      params: {
        limit: 0,
        not_empty: notEmpty ? 1 : undefined,
      },
    },
    query: {
      enabled,
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  })
}

export const useAddTeamDepartment = () => {
  const cache = useQueryClient()
  return useCreate<{ name: string }, DepartmentResponse>(PATHS.departments, {
    mutation: {
      onSuccess: () => {
        cache.invalidateQueries([OrgStructureKeys.TeamDepartments])
      },
    },
  })
}

export const useEditTeamDepartment = (id?: number) => {
  const cache = useQueryClient()
  return usePatch<{ name: string }, DepartmentResponse>(
    id ? PATHS.departmentById.replace(':id', id.toString()) : PATHS.departmentById,
    {
      mutation: {
        onSuccess: () => {
          cache.invalidateQueries([OrgStructureKeys.TeamDepartments])
          cache.invalidateQueries([OrgStructureKeys.Department, +(id || 0)])
        },
      },
    },
  )
}

export const useDeleteDepartment = (id?: number) => {
  const cache = useQueryClient()
  return useDelete(id ? PATHS.departmentById.replace(':id', id.toString()) : PATHS.departmentById, {
    mutation: {
      onSuccess: () => {
        cache.invalidateQueries([OrgStructureKeys.TeamDepartments])
        cache.invalidateQueries([OrgStructureKeys.OrgStructure])
      },
    },
  })
}

export const useGetDepartmentDetails = (id?: number | string | null) => {
  return useGetOne<DepartmentDetails>([OrgStructureKeys.Department, +(id || 0)], `${PATHS.departments}/${id}`, {
    query: {
      enabled: !!id,
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  })
}

export const useAddDepartmentJobRole = (depId?: number) => {
  const cache = useQueryClient()
  return useCreate<{ name: string } | string[], JobRoleResponse | JobRoleResponse[]>(
    depId ? PATHS.jobRoles.replace(':departmentId', depId?.toString()) : PATHS.jobRoles,
    {
      mutation: {
        onSuccess: () => {
          cache.invalidateQueries([OrgStructureKeys.Department, depId])
        },
      },
    },
  )
}

export const useEditDepartmentJobRole = (depId?: number, id?: number) => {
  const cache = useQueryClient()
  const path =
    id && depId
      ? PATHS.jobRoleById.replace(':departmentId', depId.toString()).replace(':id', id.toString())
      : PATHS.jobRoleById
  return usePatch<{ name: string }, JobRoleResponse>(path, {
    mutation: {
      onSuccess: () => {
        cache.invalidateQueries([OrgStructureKeys.Department, depId])
      },
    },
  })
}

export const useDeleteDepartmentJobRole = (depId?: number, id?: number) => {
  const path =
    id && depId
      ? PATHS.jobRoleById.replace(':departmentId', depId.toString()).replace(':id', id.toString())
      : PATHS.jobRoleById
  return useDelete(path, {})
}

export const useGetTeamLocations = (enabled = true) => {
  return useGetOne<LocationsResponse>([OrgStructureKeys.Locations], `${PATHS.locations}`, {
    axios: {
      params: {
        limit: 0,
      },
    },
    query: {
      enabled,
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  })
}

export const useGetLocationDetails = (id?: number | string | null) => {
  return useGetOne<DepartmentDetails>([OrgStructureKeys.Location, +(id || 0)], `${PATHS.locations}/${id}`, {
    query: {
      enabled: !!id,
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  })
}

export const useAddLocation = () => {
  const cache = useQueryClient()
  return useCreate<{ name: string }, LocationResponse>(PATHS.locations, {
    mutation: {
      onSuccess: () => {
        cache.invalidateQueries([OrgStructureKeys.Locations])
      },
    },
  })
}

export const useEditLocation = (id?: number) => {
  const cache = useQueryClient()
  const path = id ? PATHS.locationById.replace(':id', id.toString()) : PATHS.locationById
  return usePatch<{ name: string }, LocationResponse>(path, {
    mutation: {
      onSuccess: () => {
        cache.invalidateQueries([OrgStructureKeys.Locations])
        cache.invalidateQueries([OrgStructureKeys.Location, +(id || 0)])
      },
    },
  })
}

export const useDeleteLocation = (id?: number) => {
  const cache = useQueryClient()
  const path = id ? PATHS.locationById.replace(':id', id.toString()) : PATHS.locationById
  return useDelete(path, {
    mutation: {
      onSuccess: () => {
        cache.invalidateQueries([OrgStructureKeys.Locations])
        cache.invalidateQueries([OrgStructureKeys.OrgStructure])
      },
    },
  })
}

export const useExportOrgStructure = (params: ExportFilter) => {
  return useMutation(
    async () => {
      const response = await api.get(PATHS.export, {
        params: Object.keys(params).reduce(
          (acc, key) =>
            params[key as keyof ExportFilter] ? { ...acc, [key]: params[key as keyof ExportFilter] } : acc,
          {},
        ),
        responseType: 'blob',
      })

      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `org_structure_${format(new Date(), 'yyyy-MM-dd-hh-mm')}.${params.type}`)
      document.body.appendChild(link)
      link.click()

      return null
    },
    {
      mutationKey: [OrgStructureKeys.Export],
    },
  )
}

export const useImportOrgStructureCsv = () => {
  const cache = useQueryClient()
  const fileUpload: MutationFunction<ImportCSVResponse, { file: File; role?: Role }> = async ({ file, role }) => {
    const form = new FormData()
    form.append('file', file)
    if (role) {
      form.append('role', role)
    }

    const res: AxiosResponse<ImportCSVResponse> = await api.post(PATHS.import, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return res.data
  }

  return useMutation(fileUpload, {
    onSuccess: () => {
      cache.invalidateQueries([OrgStructureKeys.OrgStructure])
      cache.invalidateQueries([OrgStructureKeys.Filters])
    },
  })
}

export const useImportUsersFromSlack = () => {
  const cache = useQueryClient()
  return useMutation({
    mutationFn: async ({ role }: { role?: Role }) => {
      return (await api.get(PATHS.slackImport, { params: { role } })).data
    },
    mutationKey: [OrgStructureKeys.SlackImport],
    onSuccess: () => {
      cache.invalidateQueries([OrgStructureKeys.OrgStructure])
      cache.invalidateQueries([OrgStructureKeys.Filters])
    },
  })
}

export const useImportUsersFromMicrosoft = () => {
  const cache = useQueryClient()
  return useMutation({
    mutationFn: async ({ role }: { role?: Role }) => {
      return (await api.get(PATHS.microsoftImport, { params: { role } })).data
    },
    mutationKey: [OrgStructureKeys.MicrosoftImport],
    onSuccess: () => {
      cache.invalidateQueries([OrgStructureKeys.OrgStructure])
      cache.invalidateQueries([OrgStructureKeys.Filters])
    },
  })
}

export const useImportFromBambooHR = () => {
  const cache = useQueryClient()
  return useMutation({
    mutationFn: async ({ role }: { role?: Role }) => {
      return (await api.get(PATHS.bambooHRImport, { params: { role } })).data
    },
    mutationKey: [OrgStructureKeys.BambooHRImport],
    onSuccess: () => {
      cache.invalidateQueries([OrgStructureKeys.OrgStructure])
      cache.invalidateQueries([OrgStructureKeys.Filters])
    },
  })
}

export const useGetTeamUser = (id?: number | null) => {
  return useGetOne<TeamUserDetails>(
    [OrgStructureKeys.OrgStructure, id],
    id ? generatePath(PATHS.user, { id }) : PATHS.user,
    {
      query: {
        enabled: !!id,
      },
    },
  )
}

export const useFetchTeamUser = () => {
  return useMutation<TeamUserDetails, unknown, number>(async (id: number) => {
    const { data } = await api.get<TeamUserDetails>(generatePath(PATHS.user, { id }))
    return data
  })
}

export const useBulkUpdate = () => {
  const cache = useQueryClient()
  return usePatch<BulkUpdateRequest>(PATHS.bulkUpdate, {
    mutation: {
      onSuccess: () => {
        cache.invalidateQueries([OrgStructureKeys.OrgStructure])
        cache.invalidateQueries([AccountKeys.Me])
      },
    },
  })
}

export const useGetTaskStatus = () => {
  return useMutation(async (taskId: string | number) => {
    const { data: isTaskCompleted } = await api.get(`${PATHS.taskStatus}/${taskId}`)
    return isTaskCompleted
  })
}
