import cx from 'clsx'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Tooltip from '@src/components/Tooltip'
import SuggestFeatureModal from '../SuggestFeatureModal'
import { ControlledMenu, MenuItem, useClick, useHover, useMenuState } from '@szhsin/react-menu'
import { useGetResourceItems } from '../menuItems'
import { useWindowControlsOverlay } from '../utils'

import { IconExternalLink, IconHelp } from '@tabler/icons-react'
import styles from '../index.module.css'

const HelpMenu = () => {
  const ref = useRef(null)
  const [menuState, toggle] = useMenuState({ transition: true })
  const [showSuggestFeatureModal, setShowSuggestFeatureModal] = useState(false)
  const [showTooltip, setShowTooltip] = useState(true)
  const clickProps = useClick(menuState.state === 'open', toggle)
  const { anchorProps: hoverAnchorProps, hoverProps } = useHover(menuState.state, toggle)
  const isWindowControlsOverlay = useWindowControlsOverlay()
  const actualAnchorProps = isWindowControlsOverlay ? hoverAnchorProps : clickProps
  const menuProps = isWindowControlsOverlay ? hoverProps : {}
  const RESOURCE_ITEMS = useGetResourceItems(setShowSuggestFeatureModal)
  const { t } = useTranslation('common', { keyPrefix: 'navigation' })

  useEffect(() => {
    if (menuState.state === 'opening' || menuState.state === 'open') {
      window.Intercom('hide')
      setShowTooltip(false)
    } else {
      setShowTooltip(true)
    }
  }, [menuState.state])

  return (
    <>
      <Tooltip offset={10} id="tooltip_help" place="bottom" />
      <div
        className={cx(styles.navLinkButtonWrap, 'active:scale-95')}
        {...(showTooltip ? { 'data-tooltip-content': t('help') } : {})}
        data-tooltip-id="tooltip_help"
        ref={ref}
        {...actualAnchorProps}
      >
        <IconHelp className={styles.endMenuIcon} stroke={1.5} />
      </div>

      <ControlledMenu
        {...menuProps}
        {...menuState}
        anchorRef={ref}
        onClose={() => toggle(false)}
        arrow
        arrowProps={{ className: 'border-l-gray-200 border-t-gray-200 -ml-4' }}
        align="end"
        menuClassName="gap-1 w-42 !ml-[15px] bg-white rounded-base py-1.5 shadow-lg border-solid border border-gray-200"
      >
        <p className="leading-6 px-3 text-sm font-bold text-grey_1">{t('resources')}</p>

        {RESOURCE_ITEMS.map((item, index) => (
          <MenuItem className={cx(styles.navLinkButton, '!pl-3')} key={index}>
            <Link
              to={item.to}
              target={item.external ? '_blank' : '_self'}
              onClick={item?.onClick}
              className="group flex items-center justify-between w-full pr-1"
              onContextMenu={(e) => e.stopPropagation()}
            >
              {item.label}
              {item.external && (
                <IconExternalLink
                  className="opacity-0 group-hover:opacity-50 ml-1 -mt-0.5 w-4 h-4 transition-opacity"
                  stroke={2}
                />
              )}
            </Link>
          </MenuItem>
        ))}
      </ControlledMenu>
      {showSuggestFeatureModal && <SuggestFeatureModal onClose={() => setShowSuggestFeatureModal(false)} />}
    </>
  )
}

export default HelpMenu
