import React from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@components/Button'

import styles from '../index.module.css'

export interface ReportCardContentProps {
  name: string
  workflowName?: string
  isGeneratedByApproveit?: boolean
  onGenerateClick: React.MouseEventHandler<HTMLButtonElement>
}

export const ReportCardContent: React.FC<ReportCardContentProps> = ({
  name,
  workflowName,
  isGeneratedByApproveit,
  onGenerateClick,
}) => {
  const { t } = useTranslation('reports', { keyPrefix: 'card' })
  return (
    <div className="w-full overflow-y-hidden p-1">
      <div className={styles.reportCardLabel}>
        <span className={styles.reportCardTitle}>{t('nameLabel')}: </span>
        <span title={name}>{name}</span>
      </div>
      {workflowName && (
        <div className={styles.reportCardLabel}>
          <span className={styles.reportCardTitle}>{t('workflowLabel')}: </span>
          <span title={workflowName}>{workflowName}</span>
        </div>
      )}
      {isGeneratedByApproveit && (
        <div className="flex gap-1 items-center">
          <img src="/logo.svg" className="h-5 w-5" />
          <span className="text-sm text-brand_primary">{t('generatedByApproveitLabel')}</span>
        </div>
      )}

      <Button type="outlined" className="text-brand_primary mt-4" onClick={onGenerateClick}>
        {t('generateButtonLabel')}
      </Button>
    </div>
  )
}

export default ReportCardContent
