import React, { FC, useCallback, useMemo } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import Popup from '@components/Popup'
import Divider from '@components/Divider'
import { Input, Select } from '@components/Form'
import Checkbox from '@components/Checkbox'
import Button from '@components/Button'

import i18n from '@src/i18n'

import { FileType } from '@type/common'
import { useTranslation } from 'react-i18next'
import { useGenerateReport } from '@queries/Reports'
import { Period, Report } from '@models/Report'
import { requestErrorsHandler } from '@helpers/utils'
import { toast } from '@helpers/toaster'
import Datepicker from 'react-datepicker'
import { timeFormat } from '@src/constants'
import { popperModifiers } from '@src/helpers/constants'
import cx from 'clsx'

const Schema = Yup.object().shape({
  fileType: Yup.string().required(i18n.t('reports:form.fieldIsRequiredError')),
  period: Yup.string().required(i18n.t('reports:form.fieldIsRequiredError')),
  withApprovers: Yup.boolean(),
  startDate: Yup.date()
    .nullable()
    .when('period', {
      is: Period.custom,
      then: (schema) => schema.required(i18n.t('reports:generateReportForm.invalidPeriod')),
    }),
  endDate: Yup.date()
    .nullable()
    .when('period', {
      is: Period.custom,
      then: (schema) => schema.required(i18n.t('reports:generateReportForm.invalidPeriod')),
    }),
})

interface GenerateReportPopupProps {
  close(): void
  allowApprovers?: boolean
  report?: Report
  reportType?: string
}

const PeriodTitle = {
  [Period.last_week]: i18n.t('reports:generateReportForm.period.lastWeek'),
  [Period.last_month]: i18n.t('reports:generateReportForm.period.lastMonth'),
  [Period.last_year]: i18n.t('reports:generateReportForm.period.lastYear'),
  [Period.custom]: i18n.t('reports:generateReportForm.period.custom'),
}

const GenerateReportPopup: FC<GenerateReportPopupProps> = ({ close, allowApprovers = true, report, reportType }) => {
  const { t } = useTranslation('reports', { keyPrefix: 'generateReportForm' })
  const { t: tCommon } = useTranslation('common')

  const { mutateAsync: generateReport, isLoading } = useGenerateReport(report?.name || '', reportType)

  const { values, submitForm, handleChange, errors, setFieldValue } = useFormik({
    initialValues: {
      fileType: undefined,
      period: undefined,
      withApprovers: false,
      startDate: null,
      endDate: null,
    },
    validationSchema: Schema,
    validateOnChange: false,
    onSubmit: async (data, { resetForm }) => {
      try {
        const periodData =
          data.period === Period.custom
            ? {
                start_date: data.startDate,
                end_date: data.endDate,
              }
            : { period: data.period }
        const result = await generateReport({
          reportId: report?.id,
          type: data.fileType,
          with_approvers: data.withApprovers,
          ...periodData,
        })
        if (result?.status === 204) {
          return toast.warning(t('noFile'))
        }
        close()
        resetForm()
      } catch (e) {
        requestErrorsHandler(e)
      }
    },
  })

  const fileTypeOptions = useMemo(() => {
    return Object.values(FileType).map((type) => ({ value: type, label: type.toUpperCase() }))
  }, [])

  const periodOptions = useMemo(() => {
    return Object.values(Period).map((period) => ({ value: period, label: PeriodTitle[period] }))
  }, [])

  const handleChangeDates = useCallback((dates: [Date | null, Date | null]) => {
    const [start, end] = dates
    setFieldValue('startDate', start)
    setFieldValue('endDate', end)
  }, [])

  return (
    <Popup onClose={close} className="overflow-visible">
      <p className="text-lg">{t('title')}</p>
      <Divider />
      <div>
        <Select
          required
          label={t('periodSelectLabel')}
          name="period"
          options={periodOptions}
          value={values.period}
          onChange={handleChange}
          errorMessage={errors.period}
        />
        {values.period === Period.custom && (
          <Datepicker
            selectsRange
            required
            selected={values.startDate}
            wrapperClassName="w-full mb-4 relative"
            popperClassName="z-[3]"
            customInput={
              <Input
                required
                className="!mb-0"
                errorMessage={errors.startDate || errors.endDate}
                classes={{ errorIcon: cx(values.startDate && 'mr-3') }}
              />
            }
            startDate={values.startDate}
            endDate={values.endDate}
            onChange={handleChangeDates}
            dateFormat={timeFormat.date}
            popperModifiers={popperModifiers}
            isClearable
            clearButtonClassName="absolute top-2 right-0.5 h-4"
          />
        )}
        <Select
          required
          label={t('fileTypeSelectLabel')}
          options={fileTypeOptions}
          name="fileType"
          value={values.fileType}
          onChange={handleChange}
          errorMessage={errors.fileType}
        />
        {allowApprovers && (
          <Checkbox
            name="withApprovers"
            labelClassName="flex justify-revert"
            checked={values.withApprovers}
            onChange={handleChange}
          >
            {t('withApproversLabel')}
          </Checkbox>
        )}
      </div>
      <div className="flex  gap-1.5 justify-end mt-5">
        <Button color="white" onClick={close} disabled={isLoading}>
          {tCommon('back')}
        </Button>
        <Button color="success" onClick={submitForm} disabled={isLoading} loading={isLoading}>
          {tCommon('create')}
        </Button>
      </div>
    </Popup>
  )
}

export default GenerateReportPopup
