import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { subscribeWithSelector, devtools } from 'zustand/middleware'
import { produce } from 'immer'
import { ValueOf } from 'type-fest'

import { createSelectors } from '@store/utils'

import { Filters } from '@pages/Accounting/CostCenter/types'

export interface State {
  filters: Partial<Filters>
  periodOptions: Option<string>[]
}

export interface Actions {
  setFilters: (filters: Partial<Filters>) => void
  setFilter: (name: keyof Filters, value: ValueOf<Filters>) => void
  setPeriodOptions: (options: Option<string>[]) => void

  reset: () => void
}

const initialState: State = {
  filters: {},
  periodOptions: [],
}

const createCostCenter = immer<State & Actions>((set) => ({
  ...initialState,
  setFilter: (name, value) =>
    set(
      produce((state: State) => {
        state.filters = {
          ...state.filters,
          [name]: value,
        }
      }),
    ),
  setFilters: (filters) =>
    set(
      produce((state: State) => {
        state.filters = filters
      }),
    ),
  setPeriodOptions: (options) =>
    set(
      produce((state: State) => {
        state.periodOptions = options
      }),
    ),
  reset: () => set(initialState),
}))

const name = 'costCenter'
const useCostCenterStoreBase = create(devtools(subscribeWithSelector(createCostCenter), { name, store: name }))

export const useCostCenterStore = createSelectors(useCostCenterStoreBase)

export default useCostCenterStore
