import React, { useEffect } from 'react'
import { RouterProvider } from 'react-router-dom'
import Cookies from 'js-cookie'

import { useIsMutating, useIsFetching } from '@tanstack/react-query'

import Loading from '@components/Loading'
import Toaster from './components/Toast'

import router from './Routes'

import use3rdParties from '@helpers/hooks/use3rdParties'

import { loadableMutations, loadableQueries } from '@queries/Keys'

import useAmplitude from './helpers/hooks/useAmplitude'
// import './helpers/Intercom'

import styles from './app.module.css'

const Loader = () => {
  const isMutating = useIsMutating({
    predicate: (mutation) => {
      const key = mutation?.options?.mutationKey?.[0]
      return loadableMutations.includes(key as string)
    },
  })
  const isFetching = useIsFetching({
    predicate: (query) => {
      const key = Array.isArray(query.queryKey) ? query.queryKey[0] : query.queryKey
      return loadableQueries.includes(key)
    },
  })

  return <Loading loading={!!(isMutating || isFetching)} />
}

/// MOBILE BROWSER HEIGHT HACK
const vh = window.innerHeight * 0.01
document.documentElement.style.setProperty('--vh', `${vh}px`)

window.addEventListener('resize', () => {
  const vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
})
///

const App = () => {
  use3rdParties()
  useAmplitude()

  useEffect(() => {
    const token = localStorage.getItem('token')
    const refreshToken = localStorage.getItem('refreshToken')
    if (token && refreshToken) {
      Cookies.set('token', token, { expires: 7 })
      Cookies.set('refreshToken', refreshToken, { expires: 7 })
    }
  }, [])

  return (
    <div className={styles.app}>
      <RouterProvider router={router} />
      <Toaster />
      <Loader />
    </div>
  )
}

export default App
