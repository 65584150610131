import React, { useCallback, useMemo } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { SearchIcon } from '@heroicons/react/outline'

import TableV2, { useGetPagingHelpers, useGetSearchHelpers } from '@components/Table-v2'
import Input from '@components/Form/Input'
import Button from '@components/Button'
import Header from '@components/Header'
import { IconSquareRoundedPlus } from '@tabler/icons-react'

import routes from '@src/Routes/routes'
import { useGetTableColumns } from './utils'
import { useGetCostCenters } from '@queries/Accounting/costCenter'

import { useGetAllUsers } from '@queries/Teams'
import { TeamUserListItem } from '@models/Team'
import { CostCenterListItem } from '@models/CostCenters'
import { ListItem } from './types'

export interface CostCentersListProps {}

export const CostCenterList: React.FC<CostCentersListProps> = () => {
  const { t } = useTranslation('accounting')

  const { handlePageChange, getPaginatedList, limit } = useGetPagingHelpers<ListItem>()
  const { search, searchValue, handleSearchChange } = useGetSearchHelpers()

  const areFiltersSet = !!searchValue

  const navigate = useNavigate()
  const tableColumns = useGetTableColumns()

  const { data: costCentersRespose } = useGetCostCenters()
  const { data: users } = useGetAllUsers()

  const replaceOwnerIdsWithNames = (
    costCenters: CostCenterListItem[] | undefined,
    users: TeamUserListItem[],
  ): ListItem[] => {
    return (
      costCenters?.map((center) => ({
        ...center,
        owner: users.find((user) => user.id === center.owner)?.full_name || '',
      })) || []
    )
  }

  const handleAddClick = useCallback(() => navigate(generatePath(routes.private.costCenterEditById, { id: 'new' })), [])
  const handleRowClick = useCallback(
    (record: ListItem) => navigate(generatePath(routes.private.costCenterById, { id: record.id })),
    [],
  )

  const costCentersList = useMemo(() => {
    let list = replaceOwnerIdsWithNames(costCentersRespose?.results, users || [])
    if (searchValue) {
      list = search(list, (item) =>
        `${item.name} ${item.code} ${item.budget_frequency} ${item.budget_allocation_amount} ${item.currency}`
          .toLowerCase()
          .includes(searchValue.toLowerCase()),
      )
    }
    return { list: getPaginatedList(list), total: list.length }
  }, [costCentersRespose, getPaginatedList, searchValue, users])

  return (
    <>
      <Header className="pb-2" title={t('costCenter.title')}>
        <div className="flex gap-2">
          <Button onClick={handleAddClick}>
            <IconSquareRoundedPlus className="h-4 w-4" />
            {t('costCenter.addNew')}
          </Button>
        </div>
      </Header>
      <div className="grow flex flex-col gap-3">
        <div className="base flex flex-col justify-between">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:lg:grid-cols-4 gap-2 px-1">
            <div className="relative flex gap-2 sm:col-start-2 lg:col-start-3 xl:col-start-4">
              <Input
                onChange={handleSearchChange}
                classes={{ control: 'pr-8' }}
                className="!mb-0 w-full"
                placeholder="Search..."
              />
              <SearchIcon className="h-4 w-4 absolute right-3 top-2 text-gray-300" />
            </div>
          </div>
        </div>
        <TableV2
          columns={tableColumns}
          data={costCentersList.list}
          total={costCentersList.total}
          rowsPerPage={limit}
          onRowClick={handleRowClick}
          onChangePage={handlePageChange}
          handleAddClick={handleAddClick}
          noRecordsTitle={areFiltersSet ? '' : t('costCenter.list.noRecordsTitle')}
          classes={{
            addButton: 'bg-white',
          }}
        />
      </div>
    </>
  )
}

export default CostCenterList
